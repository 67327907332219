import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Button from '@redoute/button';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "button"
    }}>{`Button`}</h1>
    <p>{`The Button is a styled component that can handle a onClick action or can be a submit button.`}</p>
    <Playground __position={0} __code={'<p>\n  <Button>Button</Button>\n</p>\n<p>\n  <Button type=\"submit\">Button submit</Button>\n</p>\n<p>\n  <Button onClick={() => alert(\'click\')}>Button with onClick</Button>\n</p>\n<p>\n  <Button disabled onClick={() => alert(\'click\')}>\n    Button with onClick but disabled\n  </Button>\n</p>\n<p>\n  <Button hover>Button hover</Button>\n</p>\n<p>\n  <Button active>Button active</Button>\n</p>\n<p>\n  <Button focus>Button focus</Button>\n</p>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <p>
        <Button mdxType="Button">Button</Button>
    </p>
    <p>
        <Button type='submit' mdxType="Button">Button submit</Button>
    </p>
    <p>
        <Button onClick={() => alert('click')} mdxType="Button">Button with onClick</Button>
    </p>
    <p>
        <Button disabled onClick={() => alert('click')} mdxType="Button">Button with onClick but disabled</Button>
    </p>
    <p>
        <Button hover mdxType="Button">Button hover</Button>
    </p>
    <p>
        <Button active mdxType="Button">Button active</Button>
    </p>
    <p>
        <Button focus mdxType="Button">Button focus</Button>
    </p>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Button} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      